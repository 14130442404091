<template>
    <!-- <div class="lay-pageing-root" v-if="allCount/perPage > 1"> -->
        <div style="color: #fff;" class="d-flex align-center justify-center">
            <img 
                class="arrow"
                :style="{
                    cursor : 'pointer',
                    marginRight : pxToRem(26)
                }"
                :class="{
                    samePage : pageNo == 1
                }"
                src="@/assets/left_arrow.svg" 
                @click.prevent.stop="() => {
                    if(1 == pageNo){
                        return ; 
                    }else{
                        onPageUpdate(pageNo - 1)
                    }
            }"     
            >
            <div 
                :class="[i == pageNo ? 'pageOn' : 'pageOff','page']"
                v-for="i,index in pageNumbers"
                :key="index"
                @click.prevent.stop="onPageUpdate(i)"
            >
                {{i}}
            </div>
            <img 
                src="@/assets/right_arrow.svg" 
                :style="{
                    cursor : 'pointer',
                    marginLeft : pxToRem(26)
                }"
                :class="{
                    samePage : Math.ceil(allCount/pageCount) < pageNo+1
                }"
                class="arrow"
                @click.prevent.stop="() => {
                    if(Math.ceil(allCount/pageSize) == pageNo){
                        return ; 
                    }else{
                        onPageUpdate(pageNo+ 1)
                    }
                    
                }"
            >
        </div>
</template>

<script>
export default {
    components: {},
    props: {
        allCount: {
            default: 0
        },
        pageNo:{
            default: 1
        },
        pageSize: {
            default: 10
        },
        pageCount: {
            default: 5
        },
    },
    data() {
        return {
        };
    },
    computed: {
        pageNumbers() {
            let maxPage = Math.ceil(this.allCount / this.pageSize)
            if (maxPage < 1) {
                maxPage = 1
            }
            let startPage = (Math.ceil(this.pageNo / this.pageCount) - 1) * this.pageCount + 1;
            let endPage = startPage + this.pageCount - 1;
            if (startPage < 1) {
                startPage = 1;
                endPage = startPage + this.pageCount - 1;
            }
            if (endPage > maxPage) {
                endPage = maxPage
            }
            if (startPage < 1) {
                startPage = 1
            }
            let items = []
            for(let i = startPage; i <= endPage; i++) {
                items.push(i);
            }
            return items
        }
    },
    watch: {
    },
    created() {},
    mounted() {},
    destroyed() {},
    methods: {
        onPageUpdate(page) {
            if(this.pageNo != page) {
                this.$emit("onPageUpdate", page)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";
.pageOn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #111111;
}
.samePage{
    opacity: 0.15;
}

.pageOff{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #111111;
    display: flex;
    color: #EFEFEF;
    justify-content: center;
    align-items: center;
}
.arrow{
    width: 24px;
    height: 24px;
}
@include laptop{
    .page{
        width: 24px;
        height: 24px;
        font-weight: 500;
        font-size: 14px;
    }
    .arrow{
        width: 16px;
        height: 16px;
    }
}
.page{
    cursor: pointer;
    margin: 0px 6px;
}
</style>
