var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"color":"#fff"}},[_c('img',{staticClass:"arrow",class:{
                samePage : _vm.pageNo == 1
            },style:({
                cursor : 'pointer',
                marginRight : _vm.pxToRem(26)
            }),attrs:{"src":require("@/assets/left_arrow.svg")},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (() => {
                if(1 == _vm.pageNo){
                    return ; 
                }else{
                    _vm.onPageUpdate(_vm.pageNo - 1)
                }
        }).apply(null, arguments)}}}),_vm._l((_vm.pageNumbers),function(i,index){return _c('div',{key:index,class:[i == _vm.pageNo ? 'pageOn' : 'pageOff','page'],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onPageUpdate(i)}}},[_vm._v(" "+_vm._s(i)+" ")])}),_c('img',{staticClass:"arrow",class:{
                samePage : Math.ceil(_vm.allCount/_vm.pageCount) < _vm.pageNo+1
            },style:({
                cursor : 'pointer',
                marginLeft : _vm.pxToRem(26)
            }),attrs:{"src":require("@/assets/right_arrow.svg")},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return (() => {
                if(Math.ceil(_vm.allCount/_vm.pageSize) == _vm.pageNo){
                    return ; 
                }else{
                    _vm.onPageUpdate(_vm.pageNo+ 1)
                }
                
            }).apply(null, arguments)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }